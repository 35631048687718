import React, { useEffect } from 'react';

import { SEO } from '../components';
import { Background, Product } from '../lib/types';
import { useAppDispatch } from '../redux/hooks';
import { changeBackground, changeCurrentLogo } from '../redux/reducers/ui';
import { PageLayout, Planets } from '../widgets';

import '../assets/fonts/jakarta.css';

const IndexPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeBackground(Background.Main));
    dispatch(changeCurrentLogo({ product: Product.Arteria }));
  }, [dispatch]);

  return (
    <>
      <SEO
        title="Arteria"
        description="Emerging markets are complicated, we're not"
        image="../assets/images/logos/arteria.svg"
      />
      <PageLayout isOverflowActive>
        <Planets />
      </PageLayout>
    </>
  );
};

export default IndexPage;
